@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/static/pretendard-dynamic-subset.css");

body {
  margin: 0;
  font-family: "Pretendard";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Pretendard";
}
.flex {
  display: flex;
  align-items: center;
}
textarea {
  resize: none;
}
button {
  cursor: pointer;
}
